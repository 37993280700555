import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import DetrainAnimation_gif from '../images/Detrain-Cloud.gif'
import { Button } from "react-bootstrap"

const HeritagePage = () => {
  return (
    <Layout pageTitle="INCUS Heritage">
      <h1>INCUS Heritage</h1>
      <h4>
      The instruments onboard the INCUS satellites have a heritage with two flight-tested NASA missions: RainCube and TEMPEST-D.      </h4>
      <h2>INCUS Radar Heritage: RainCube</h2>

      <StaticImage
        alt="An illustration of the RainCube Satellite over earth"
        src="../images/raincube_pic.jpg"
        layout="constrained"
        placeholder="blurred"
      />
      <p>
      The INCUS Ka-Band Radar is derived from the flight-tested RainCube Satellite, which flew from 2018 to 2020. Like the INCUS radar, RainCube used a deployable antenna to efficiently pack a powerful Ka-Band precipitation sensing radar into a small, efficient package.       
      </p>
      <a href="https://www.jpl.nasa.gov/missions/radar-in-a-cubesat-raincube" target="_blank"><Button>Learn more about RainCube</Button></a>
      <h2>INCUS Radiometer Heritage: TEMPEST-D</h2>

      <StaticImage
        alt="An illustration of the TEMPEST-D Satellite over earth"
        src="../images/tempest_d_satellite.jpg"
        layout="constrained"
        placeholder="blurred"
      />
      <p>
      The INCUS Microwave Radiometer, which will be deployed on the middle INCUS satellite, comes from the radiometer demonstrated onboard the flight-tested TEMPEST-D Satellite, which flew from 2018 to 2020. TEMPEST-D demonstrated the ability to inexpensively, but effectively, measure microwave radiance from space.       </p>
      <a href="https://www.jpl.nasa.gov/missions/temporal-experiment-for-storms-and-tropical-systems-demonstration-tempest-d" target="_blank"><Button>Learn more about TEMPEST-D</Button></a>


    </Layout >

  )
}

export default HeritagePage

